import React from 'react';
import banner from './images/banner.png';
import boden from './images/boden.webp';
import cumala from './images/cumala.webp';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXTwitter } from '@fortawesome/free-brands-svg-icons'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

const JEOWIFCUM_PUMPFUN_URL = 'https://pump.fun/7DPSaKYuiFUSEBpHnjPpb3AGVcLRjXSjhsotnMevwySk'
const JEOWIFCUM_X_URL = 'https://x.com/jeowifcum'
const BODEN_URL = 'https://bodenonsol.xyz/'
const CUM_URL = 'https://cumalaharris.godaddysites.com/'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-logo-container">
          <a href="/logo.png" target="_blank">
            <img src='/logo.png' className="App-logo" alt="logo" />
          </a>
          <div className='App-logo-text'>jeo wif cum</div>
        </div>
        <div id='App-header-menu'>
          <a href={JEOWIFCUM_X_URL} target="_blank" rel="noopener noreferrer">
            <Button variant="link">
              <FontAwesomeIcon icon={faXTwitter} size="2xl" />
            </Button>
          </a>
        </div>
        <div id='App-header-buy'>
          <a href={JEOWIFCUM_PUMPFUN_URL} target="_blank" rel="noopener noreferrer">
            <Button variant="success">Buy $jeowifcum</Button>
          </a>
        </div>
      </header>
      <main>
        <img src={banner} className="App-banner" alt="banner" />
        <div id="App-intro">
          The <b>#jeowifcum4prez</b> cumpaign is 100% cummunity funded with a <a href="https://pump.fun" target="_blank" rel="noopener noreferrer">pump.fun</a> fair launch.
        </div>
        <div className='App-testimony-container'>
          <div>
            <div>the cum stays on.</div>
            <div>
              - cumala harris
            </div>
            <br/>
            <div className="App-learn-more-link">
              learn more about $cum <a href={CUM_URL} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ marginBottom: '6px' }} /></a>
            </div>
          </div>
          <img src={cumala} className="App-testimony-image" alt="cumala-testimony" />
        </div>
        <div className='App-testimony-container'>
          <img src={boden} className="App-testimony-image App-testimony-image-jeo" alt="jeo-testimony" />
          <div>
            <div>
              i aproov dis message or whatevs.
            </div>
            <div>
              - jeo boden
            </div>
            <br/>
            <div className="App-learn-more-link">
              learn more about $boden <a href={BODEN_URL} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ marginBottom: '6px' }} /></a>
            </div>
          </div>
        </div>
        <div id='App-final-cta'>
          <a href={JEOWIFCUM_PUMPFUN_URL} target="_blank" rel="noopener noreferrer">
            <Button size="lg" variant="success">Buy $jeowifcum</Button>
          </a>
        </div>
        <div id="App-certs">
          <ul>
            <li>
              CA: 7DPSaKYuiFUSEBpHnjPpb3AGVcLRjXSjhsotnMevwySk
            </li>
          </ul>
        </div>
      </main>
      <footer>
        Dis is memecoin wif no intrinsic value or expectation of financial return.<br/>
      </footer>
    </div>
  );
}

export default App;
